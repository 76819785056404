// import logo from './logo.svg';
// import './App.css';
// import Video from './utils/Video';
// import AgoraVideoCall from "./components/AgoraVideoCall";
// import { AGORA_APP_ID } from "./agora.config";
// import Meeting from './pages/meeting'
import Index from './pages/index';
import { HashRouter as Router, Route } from 'react-router-dom'
import Meeting from './pages/meeting';

function App() {
  return (
    // <Meeting/>
    // <Video/>
    <Router>
    <div className="full">
      <Route exact path="/" component={Index} />
      <Route path="/meeting" component={Meeting} />
    </div>
  </Router>
  );
}

export default App;
