import React, { Component } from 'react';
import AgoraRTC from "agora-rtc-sdk-ng";
import { AGORA_APP_ID, AGORA_APP_CERTIFICATE } from "../../agora.config";
import Button from '@material-ui/core/Button';
const { RtcTokenBuilder, RtmTokenBuilder, RtcRole, RtmRole } = require('agora-access-token')
// const client = AgoraRTC.createClient({ mode: "live", codec: "vp8" });


class Video extends Component {
    constructor(props) {
        super(props);
        this.rtc = {
            // For the local client.
            client: null,
            // For the local audio and video tracks.
            localAudioTrack: null,
            localVideoTrack: null,
        };

        this.options = {
            account: props.account,
            // Pass your app ID here.
            appId: AGORA_APP_ID,
            // Set the channel name.
            channel: props.channel,
            // Pass a token if your project enables the App Certificate.
            token: null,
            // token: this.getToken(),
            // token: "0069e0cbc55b92b464880d48feefc47fdcaIADukuKrSsS/InPVLHi5sGOH/8s9+rpjaSsEE4SQMl+Wxgx+f9gAAAAAEADqgOQ9Tpw7YAEAAQBPnDtg",
        };



        this.startBasicCall();

    }
    async getToken() {
        const appID = AGORA_APP_ID;
        const appCertificate = AGORA_APP_CERTIFICATE;
        const channelName = this.options.channel;
        // const uid = 2882341273;
        const account = this.options.account;
        const role = RtcRole.PUBLISHER;

        const expirationTimeInSeconds = 3600

        const currentTimestamp = Math.floor(Date.now() / 1000)

        const privilegeExpiredTs = currentTimestamp + expirationTimeInSeconds

        // IMPORTANT! Build token with either the uid or with the user account. Comment out the option you do not want to use below.

        // Build token with uid
        // const tokenA = RtcTokenBuilder.buildTokenWithUid(appID, appCertificate, channelName, uid, role, privilegeExpiredTs);
        // console.log("Token With Integer Number Uid: " + tokenA);

        // Build token with user account
        // const tokenB = RtcTokenBuilder.buildTokenWithAccount(appID, appCertificate, channelName, account, role, privilegeExpiredTs);
        const tokenB = RtcTokenBuilder.buildTokenWithAccount(appID, appCertificate, channelName, account, 1, privilegeExpiredTs);
        console.log("Token With UserAccount: " + tokenB);

        this.options.token = tokenB;
        // return tokenB;
    }
    async startBasicCall() {
        await this.getToken();
        console.log("this.option.token: ", this.options.token);
        this.rtc.client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
        this.rtc.client.on("user-published", async (user, mediaType) => {
            // Subscribe to a remote user.
            await this.rtc.client.subscribe(user, mediaType);
            console.log("subscribe success");

            // If the subscribed track is video.
            if (mediaType === "video") {
                // Get `RemoteVideoTrack` in the `user` object.
                const remoteVideoTrack = user.videoTrack;
                // Dynamically create a container in the form of a DIV element for playing the remote video track.
                const playerContainer = document.createElement("div");
                // Specify the ID of the DIV container. You can use the `uid` of the remote user.
                playerContainer.id = user.uid.toString();
                playerContainer.style.width = "640px";
                playerContainer.style.height = "480px";
                document.body.append(playerContainer);

                // Play the remote video track.
                // Pass the DIV container and the SDK dynamically creates a player in the container for playing the remote video track.
                remoteVideoTrack.play(playerContainer);

                // Or just pass the ID of the DIV container.
                // remoteVideoTrack.play(playerContainer.id);
            }

            // If the subscribed track is audio.
            if (mediaType === "audio") {
                // Get `RemoteAudioTrack` in the `user` object.
                const remoteAudioTrack = user.audioTrack;
                // Play the audio track. No need to pass any DOM element.
                remoteAudioTrack.play();
            }
        });
        this.rtc.client.on("user-unpublished", user => {
            // Get the dynamically created DIV container.
            const playerContainer = document.getElementById(user.uid);
            // Destroy the container.
            playerContainer.remove();
        });
        console.log(this.options.account);
        const uid = await this.rtc.client.join(this.options.appId, this.options.channel, this.options.token, this.options.account);
        // const uid = await this.rtc.client.join(this.options.appId, this.options.channel, this.options.token, null);
        // Create an audio track from the audio sampled by a microphone.
        this.rtc.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
        // Create a video track from the video captured by a camera.
        this.rtc.localVideoTrack = await AgoraRTC.createCameraVideoTrack();
        // Publish the local audio and video tracks to the channel.
        await this.rtc.client.publish([this.rtc.localAudioTrack, this.rtc.localVideoTrack]);

        console.log("publish success!");
    }

    async leaveCall() {
        // Destroy the local audio and video tracks.
        this.rtc.localAudioTrack.close();
        this.rtc.localVideoTrack.close();

        // Traverse all remote users.
        this.rtc.client.remoteUsers.forEach(user => {
            // Destroy the dynamically created DIV container.
            const playerContainer = document.getElementById(user.uid);
            playerContainer && playerContainer.remove();
        });

        // Leave the channel.
        await this.rtc.client.leave();
    }

    render() {
        return (
            <div>
                {/* <Button
                    id="leaveBtn"
                    variant="contained"
                    color="primary"
                    onClick={this.leaveCall()}
                >Leave</Button> */}
            </div>
        );

    }

}

export default Video;