import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import './index.css';
import * as Cookies from 'js-cookie';
import Meeting from '../meeting';

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            channel: "",
            account: "",
            meetings: [],
        };
    }
    handleJoin = () => {
        // if (!this.state.joinBtn) {
        //   return
        // }
        console.log(this.state);
        Cookies.set('channel', this.state.channel);
        Cookies.set('account', this.state.account);
        // Cookies.set('baseMode', this.state.baseMode)
        // Cookies.set('transcode', this.state.transcode)
        // Cookies.set('attendeeMode', this.state.attendeeMode)
        // Cookies.set('videoProfile', this.state.videoProfile)
        window.location.hash = "meeting"
    }
    handleTextFeildChange = (e) => {
        this.setState(
            {
                [e.target.name]: e.target.value
            }
        );
    }
    addChannel = () => {
        console.log(`addChannel: channel:${this.state.channel}, account: ${this.state.account}`);
        // let meeting = {
        //     channel: this.state.channel,
        //     account: this.state.account};
        let meeting = <Meeting
            key={this.state.channel}
            channel={this.state.channel}
            account={this.state.account} />;
        this.setState({
            meetings: this.state.meetings.concat(meeting)
        });
    }
    render() {
        return (
            <div id="texffield-wraper">
                <TextField
                    id="channel-textfield"
                    name="channel"
                    label="channel"
                    variant="outlined"
                    onChange={this.handleTextFeildChange}
                />
                <TextField
                    id="account-textfield"
                    name="account"
                    label="account"
                    variant="outlined"
                    onChange={this.handleTextFeildChange}
                />
                <Button
                    id="joinBtn"
                    variant="contained"
                    color="primary"
                    // onClick={this.handleJoin}
                    onClick={this.addChannel}
                >Join</Button>
                {this.state.meetings}
            </div>
        );
    }

}
export default Index;